import React, { Fragment } from "react";
import Description from "./Description";

const Main = () => {
	return (
		<Fragment>
			<Description />
		</Fragment>
	);
};

export default Main;
